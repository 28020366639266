import React, { useState } from 'react';
import { defaultMessages, initialTwilioChatState } from '../components/Rebrand/TwilioChat2/utils/chats';

const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
  const [themeDark, setThemeDark] = useState(false);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);
  const [cartTotal, setCartTotal] = useState(3);
  const [fullScreenSearch, setFullScreenSearch] = useState(false);
  const [fullScreenSearchInputRef, setFullScreenSearchInputRef] = useState({});
  const [fullScreenLoader, SetFullScreenLoader] = useState(false);
  const [registrationData, setRegistrationData] = useState(null);
  const [headerMinimized, setHeaderMinimized] = useState(false);
  const [productContractFilter, setProductContractFilter] = useState('contractHigh');
  const [quoteLoading, setQuoteLoading] = useState(false)
  const [totalLoading, setTotalLoading ] = useState(false)
  const [headerVisible, setHeaderVisible] = useState(true)
  const [footerVisible, setFooterVisible] = useState(true)
  const [APIErrorMsg, setAPIErrorMsg] = useState({ title: '', message: '' })
  const [faqArticles, setFaqArticles] = useState([])
  const [faqCategories, setFaqCategories] = useState([])
  const [twilioChat, setTwilioChat] = useState({});

  const initialAddonsSelected = {
    'Static IP': null,
    'YouMesh': null,
    'YouMesh Pro': null,
    'YouPhone': null
  }

  // This needs to be dynamic
  const [addonsSelected, setAddonsSelected] = useState(initialAddonsSelected);

  const initialSteps = {
    1: {
      name: 'Speed',
      stepVisited: true
    },
    2: {
      name: 'Go live',
      stepVisited: false
    },
    3: {
      name: 'Add ons',
      stepVisited: false
    },
    4: {
      name: 'Payment',
      stepVisited: false
    },
    5: {
      name: 'Confirmation',
      stepVisited: false
    }
  }

  const initialRecontractSteps = {
    1: {
      name: 'Speed',
      stepVisited: true
    },
    2: {
      name: 'Add ons',
      stepVisited: true
    },
    3: {
      name: 'Summary',
      stepVisited: true
    },
    4: {
      name: 'Checkout',
      stepVisited: false
    },
  }

  const [steps, setSteps] = useState(initialSteps);
  const [recontractSteps, setRecontractSteps] = useState(initialRecontractSteps);

  const initialToggleState = {
    'YouPhone Plus': false,
    'YouPhone Pro': false,
    'Battery Pack': false,
    'Transfer landline number': false
  }

  const [toggleState, setToggleState] = useState(initialToggleState)

  const toggleTheme = () => {
    setThemeDark(!themeDark);
  };

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible);
  };

  const toggleErrorModal = () => {
    setErrorModalVisible(!errorModalVisible);
  };

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas);
  };

  const goHeaderMinimized = () => {
    setHeaderMinimized(true);
  };

  const goHeaderMaximized = () => {
    setHeaderMinimized(false);
  };

  const incCartTotal = () => {
    setCartTotal(cartTotal + 1);
  };

  const decCartTotal = () => {
    setCartTotal(cartTotal - 1);
  };

  const toggleFullScreenSearch = () => {
    if (typeof window !== 'undefined' && window && !fullScreenSearch) {
      setFullScreenSearch(true);
      document.body.classList.add('modal-open');

      if (fullScreenSearchInputRef.current) {
        setTimeout(() => {
          fullScreenSearchInputRef.current.focus();
        }, 800);
      }
    } else {
      setFullScreenSearch(false);
      document.body.classList.remove('modal-open');
    }
  };

  const showFullScreenSearch = () => {
    if (typeof window !== 'undefined' && window) {
      setFullScreenSearch(true);
      document.body.classList.add('modal-open');
    }

    if (fullScreenSearchInputRef && fullScreenSearchInputRef.current) {
      setTimeout(() => {
        fullScreenSearchInputRef.current.focus();
      }, 800);
    }
  };

  const hideFullScreenSearch = () => {
    if (typeof window !== 'undefined' && window) {
      setFullScreenSearch(false);
      document.body.classList.remove('modal-open');
    }
  };

  const showFullScreenLoader = () => {
    if (typeof window !== 'undefined' && window) {
      SetFullScreenLoader(true);
      document.body.classList.add('modal-open');
    }
  };

  const hideFullScreenLoader = () => {
    if (typeof window !== 'undefined' && window) {
      SetFullScreenLoader(false);
      document.body.classList.remove('modal-open');
    }
  };

  const goSetRegistrationData = (registrationData) => {
    setRegistrationData(registrationData)
    if (typeof window !== 'undefined' && window) {
      localStorage.setItem('registrationData', JSON.stringify(registrationData));
    }
    // If registration data is reset than reset other data
    if (registrationData === null) {
      if (typeof window !== 'undefined' && window) { 
        localStorage.setItem('addonsSelected', JSON.stringify(initialAddonsSelected));
        localStorage.setItem('toggleState', JSON.stringify(initialToggleState));
        localStorage.setItem('steps', JSON.stringify(initialSteps));
      }
    }
  }

  const goResetRegistrationData = () => {
    goSetRegistrationData({
      ...goGetRegistrationData(),
      searchPremiseResults: undefined,
      personalData: undefined,
      currentProvider: undefined,
      checkoutStep: undefined,
      currentContractType: undefined,
      addressId: undefined,
      dateFilter: undefined,
      accountNumber: undefined,
      totalPrice: undefined,
      leadId: undefined,
      branchCode: undefined,
      registerStep: undefined,
      orderItems: goGetRegistrationData()?.orderItems?.filter(item => item.properties.Type === 'BASE_PRODUCT'),
      orderId: undefined,
      addonPrices: undefined,
      phoneNumber1: undefined,
      phoneNumber2: undefined,
      phoneNumber3: undefined,
      LOAAuthorized: undefined,
      portFromProvider: undefined,
      hasVoice: undefined,
      skipBooking: false,
      recontractResponse: undefined,
      containsYouPhone: undefined,
      otsOrderId: undefined,
      otsStatus: undefined,
      earlierAvailability: undefined
    })

    if (typeof window !== 'undefined' && window) { 
      localStorage.setItem('addonsSelected', JSON.stringify(initialAddonsSelected));
      localStorage.setItem('toggleState', JSON.stringify(initialToggleState));
      localStorage.setItem('steps', JSON.stringify(initialSteps));
    }


  }

  const goGetRegistrationData = () => {
    if (registrationData) {
      return registrationData
    } else if (typeof window !== 'undefined' && window && localStorage.getItem('registrationData')) {
      var regData = localStorage.getItem('registrationData')
      if (regData && regData !== "undefined") {
        return JSON.parse(regData)
      }
    } else {
      return undefined
    }
  }

  const goSetAddonsSelected = (addonsSelected) => {
    setAddonsSelected(addonsSelected)
    if (typeof window !== 'undefined' && window) {
      localStorage.setItem('addonsSelected', JSON.stringify(addonsSelected));
    }
  }

  const goGetAddonsSelected = () => {
    if (typeof window !== 'undefined' && window && localStorage.getItem('addonsSelected')) {
      var addSelec = localStorage.getItem('addonsSelected')
      if (addSelec && addSelec !== "undefined") {
        return JSON.parse(addSelec)
      }
    } else {
      return addonsSelected
    }
  }

  const goSetToggleState = (toggleState) => {
    setToggleState(toggleState)
    if (typeof window !== 'undefined' && window) {
      localStorage.setItem('toggleState', JSON.stringify(toggleState));
    }
  }

  const goGetToggleState = () => {
    if (typeof window !== 'undefined' && window && localStorage.getItem('toggleState')) {
      var togSet = localStorage.getItem('toggleState')
      if (togSet && togSet !== "undefined") {
        return JSON.parse(togSet)
      }
    } else {
      return toggleState
    }
  }

  const goSetRecontractSteps = (recontractSteps) => {
    setToggleState(recontractSteps)
    if (typeof window !== 'undefined' && window) {
      localStorage.setItem('recontractSteps', JSON.stringify(recontractSteps));
    }
  }

  const goSetSteps = (steps) => {
    setToggleState(steps)
    if (typeof window !== 'undefined' && window) {
      localStorage.setItem('steps', JSON.stringify(steps));
    }
  }

  const goGetSteps = () => {
    if (typeof window !== 'undefined' && window && localStorage.getItem('steps')) {
      var stps = localStorage.getItem('steps')
      if (stps && stps !== "undefined") {
        return JSON.parse(stps)
      }
    } else {
      return steps
    }
  }

  const goGetRecontractSteps = () => {
    if (typeof window !== 'undefined' && window && localStorage.getItem('recontractSteps')) {
      var stps = localStorage.getItem('recontractSteps')
      if (stps && stps !== "undefined") {
        return JSON.parse(stps)
      }
    } else {
      return recontractSteps
    }
  }

  const goSetTwilioChat = (twilioChat) => {
    twilioChat && setTwilioChat(twilioChat);
    if (typeof window !== 'undefined' && window) {
      // We don't want to have "messages" in Local Storage
      const { messages, ...NewTwilioChat } = twilioChat;
      localStorage.setItem('twilioChat', JSON.stringify(NewTwilioChat));
    }
  }

  const goGetTwilioChat = () => {
    return twilioChat;
  }
  
  const goResetTwilioChat = () => {
    const tmpTwilioChat = {...initialTwilioChatState,
      openChat: twilioChat.openChat,
      fullscreen: twilioChat.fullscreen,
      side: twilioChat.side
    };
    setTwilioChat(tmpTwilioChat);
    const { messages, ...NewTwilioChat } = tmpTwilioChat;
    localStorage.setItem('twilioChat', JSON.stringify(NewTwilioChat));
  }
  
  return (
    <GlobalContext.Provider
      value={{
        themeDark,
        toggleTheme,
        headerMinimized,
        goHeaderMaximized,
        goHeaderMinimized,
        videoModalVisible,
        toggleVideoModal,
        visibleOffCanvas,
        toggleOffCanvas,
        cartTotal,
        incCartTotal,
        decCartTotal,
        setFullScreenSearchInputRef,
        fullScreenSearch,
        toggleFullScreenSearch,
        showFullScreenSearch,
        hideFullScreenSearch,
        showFullScreenLoader,
        hideFullScreenLoader,
        fullScreenLoader,
        registrationData,
        setRegistrationData,
        errorModalVisible,
        setErrorModalVisible,
        toggleErrorModal,
        steps,
        setSteps,
        toggleState,
        setToggleState,
        addonsSelected,
        setAddonsSelected,
        goSetRegistrationData,
        goGetRegistrationData,
        goSetAddonsSelected,
        goGetAddonsSelected,
        goSetToggleState,
        goGetToggleState,
        goSetSteps,
        goGetSteps,
        goResetRegistrationData,
        goSetRecontractSteps,
        goGetRecontractSteps,
        productContractFilter,
        setProductContractFilter,
        quoteLoading,
        totalLoading,
        setQuoteLoading,
        setTotalLoading,
        APIErrorMsg,
        setAPIErrorMsg,
        headerVisible,
        setHeaderVisible,
        footerVisible,
        setFooterVisible,
        faqArticles,
        setFaqArticles,
        faqCategories,
        setFaqCategories,
        goGetTwilioChat,
        goSetTwilioChat,
        goResetTwilioChat,
        twilioChat,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
