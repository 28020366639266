export const menuItems = [
  {
    name: "why-youfibre",
    label: "Our Broadband",
    items: null
  },
  {
    name: "#",
    label: "About",
    items: [
      {
        name: "why-youfibre",
        label: "Why You?",
        items: null
      },
      {
        name: "careers",
        label: "Careers",
        items: null
      },
      {
        name: "news",
        label: "News",
        items: null
      },
      {
        name: "reviews",
        label: "Reviews",
        items: null
      }
    ]
  },
  {
    name: "business",
    label: "Business",
    items: null
  },
  /*
  {
    name: "landlord",
    label: "Landlord",
    items: null
  },
  */
  {
    name: "#",
    label: "Support",
    items: [
      {
        name: "https://support.youfibre.com/knowledge-base",
        label: "Help",
        items: null,
        isExternal: true
      },
      {
        name: "voucher",
        label: "Vouchers",
        items: null
      },
      {
        name: "socialtariff",
        label: "Social Tariffs",
        items: null
      },
      {
        name: "#chatnow",
        label: "Contact",
        items: null
      }
    ]
  },
  {
    name: "refer",
    label: "Refer & Earn",
    items: null
  }
];
