import React, { useContext, useState, useEffect, useRef } from "react";
import { io, Socket } from 'socket.io-client';
import styled from 'styled-components';
import GlobalContext from "../../../../context/GlobalContext";
import Message from "./Message";
import moment from 'moment';
import { categories, defaultMessages, initialTwilioChatState, newAccountHolder, notAccountHolder, userTypeLabels, yesAccountHolder } from "../utils/chats";
import { connectToWebsocket, createConversation, didAgentPost, getAllMessages, getFiles, getToken, scrollToBottom, sendMessage } from "../services/chatService";
import { v4 as uuidv4 } from 'uuid';
import TypingIndicator from "./TypingIndicator";
import ChatContentList from "./styled/ChatContentList";
import Splitter from "./styled/Splitter";

interface Message {
  createdAt: string;
  from?: string | null;
  files?: string | null;
  message: string | null;
  form: FormType | null;
  messageId: string;
  fromId: string;
  senderType: string;
}

interface FormType {
  type: string;
  value: Array<{ title: string; action: string; placeholder?: string | null; type?: string; required?: boolean; options?:object }>;
}
  
type UserType = 'NEW_CUSTOMER' | 'ACCOUNT_HOLDER' | 'NOT_ACCOUNT_HOLDER';

const MessageList = ({...props}) => {
  const gContext: any = useContext(GlobalContext);
  const [chatSessionId, setChatSessionId] = useState<string | null>(null);
  const [chatForm, setChatForm] = useState<{ [key: string]: any }>({});
  const [messages, setMessages] = useState<Message[]>(defaultMessages);
  const messagesRef = useRef<Message[]>(messages);
  const sc = useRef<Socket | null>(null);

  const messageRef = useRef<Message[]>([]);
  const [message, setMessage] = useState<Message|null>(null);
  const [newMessage, setNewMessage] = useState<boolean>(false);

  useEffect(() => {
 
  }, []);
  
  useEffect(() => {
    if(gContext.twilioChat.chatUserId){
      const tmpUUID = chatSessionId || uuidv4();
      setChatSessionId(tmpUUID);
      localStorage.setItem('chatSessionId', tmpUUID);  
      if(!sc.current) sc.current = connectToWebsocket(tmpUUID);
      sc.current?.on('connect', () => {
        console.log('Connected to WebSocket server');
      });
      sc.current?.on("connect_error", (err) => {
        console.log(`Connect error due to ${err.message}`);
        sc.current = null;
        sc.current = connectToWebsocket(gContext.twilioChat.chatUserId);
      });  
      sc.current?.on("disconnect", (reason) => {
        console.log(`Disconnected: ${reason}`);
        sc.current = null;
        sc.current = connectToWebsocket(gContext.twilioChat.chatUserId);
      });
    } else {
      setMessages(defaultMessages);
    }
  }, [gContext.twilioChat.chatUserId]);
  
  useEffect(() => {
    if(gContext.twilioChat.chatCaseId){
      // Subscribe to room with Case ID
      sc.current && gContext.twilioChat.chatCaseId && sc.current.emit("joinRoom", gContext.twilioChat.chatCaseId);  
      // Listen events only for our Case/Conversation
      sc.current && !sc.current.listeners(`case-event-${gContext.twilioChat.chatCaseId}`).length && sc.current?.on(`case-event-${gContext.twilioChat.chatCaseId}`, (data) => {    
        switch(data.type){
          case "CASE_UPDATED": 
            gContext.twilioChat.agentId = data.data.properties?.OwnerId;
            // gContext.goSetTwilioChat({...gContext.twilioChat, agentId: gContext.twilioChat.agentId});
            break;
          case "CONVERSATION_CLOSED":
            gContext.goSetTwilioChat({
              ...initialTwilioChatState,
              openChat: gContext.twilioChat.openChat,
              fullscreen: gContext.twilioChat.fullscreen,
              side: gContext.twilioChat.side,
              chatClosing: data.data.properties.ClosedBy !== 'CUSTOMER'
            });
            gContext.twilioChat.chatCaseId && sc?.current?.emit("leaveRoom", gContext.twilioChat.chatCaseId);
            break;
          case "MESSAGE_CREATED": receiveMessage(data.data, true); break;
          default: break;
        }
      });
    }
  }, [gContext.twilioChat.chatCaseId]);
  
  useEffect(() => {
    if(message){
      messagesRef.current = messagesRef.current?.filter(item => !item.form);
      setMessages(messagesRef.current);
      scrollToBottom();
      setTimeout(() => {
        // Merge new messages with existing ones with unique messageId
        const tmpMessages = [...new Map([message, ...messagesRef.current].map(item => [item.messageId, item])).values()];
        messagesRef.current = tmpMessages;
        let tmpState = {
          ...gContext.twilioChat, 
          messages: tmpMessages, 
          chatUnreaded: gContext.twilioChat.chatConversationId && (!gContext.twilioChat.openChat || !gContext.twilioChat.visibilityChange),
          typingAgent: false, 
          agentId: gContext.twilioChat.agentId, 
          lastTimeActive: Date.now()
        };
        // Check if Agent has posted to start timer
        if(didAgentPost(messages)){ 
          tmpState = {...tmpState, lastTimeActive: Date.now()};
        }          
        gContext.goSetTwilioChat(tmpState);
        scrollToBottom();
        setMessage(null);
      }, 500);
    }
  }, [message]);
  
  useEffect(() => {
    setMessages(gContext.twilioChat.messages);
    messagesRef.current = gContext.twilioChat.messages;
  }, [gContext.twilioChat.messages]);
  
  let newMessageInterval:any;
  
  useEffect(() => {
    // Buffer for new messages
    if(newMessage && messageRef.current.length > 0 && !newMessageInterval){
      newMessageInterval = setInterval(() => {
        const [first, rest] = [messageRef.current[0], messageRef.current.slice(1) || []];
        messageRef.current = rest;
        setMessage(first);
        scrollToBottom();
        if(messageRef.current.length === 0){
          setNewMessage(false);
          clearInterval(newMessageInterval);
        }
      }, 600);   
    } else if (!newMessage) {
      setNewMessage(false);
      clearInterval(newMessageInterval);
    } else {

    }
  }, [newMessage]);
    
  const buttonAction = (action) => {
    setTimeout(() => {
      switch(action){
        case 'new_customer': 
          gContext.goSetTwilioChat({...gContext.twilioChat, chatUserType: 'NEW_CUSTOMER'});
          messageRef.current = newAccountHolder;
          setNewMessage(true);
          break;
        case 'not_customer': 
          gContext.goSetTwilioChat({...gContext.twilioChat, chatUserType: 'NOT_ACCOUNT_HOLDER'});
          messageRef.current = notAccountHolder;
          setNewMessage(true);
          break;
        case 'old_customer': 
          gContext.goSetTwilioChat({...gContext.twilioChat, chatUserType: 'ACCOUNT_HOLDER'});
          messageRef.current = yesAccountHolder;
          setNewMessage(true);
          break;
        case 'submit_form':
            chatForm.full_name && 
            chatForm.address && chatForm.address !== '/' && 
            chatForm.email && 
            gContext.twilioChat.chatUserType && 
            (((gContext.twilioChat.chatUserType === "ACCOUNT_HOLDER") && typeof chatForm.last_bill !== "undefined") || (gContext.twilioChat.chatUserType !== "ACCOUNT_HOLDER")) &&
            chatForm.category && 
            submitForm(`<b>${userTypeLabels[gContext.twilioChat.chatUserType]}</b><br /><b>Full name</b>: ${chatForm.full_name}<br /><b>Email</b>: ${chatForm.email}<br /><b>Address</b>: ${chatForm.address}<br />${(typeof chatForm.last_bill !== 'undefined')?'<b>What was the total value of your last bill</b>: '+chatForm.last_bill+'<br />':''}<b>What do you need help with today</b>: ${categories[chatForm.category]}`);
          break;
        default: break;
      }
    }, 500);
  };

  const submitForm = async (message:string = '') => { 
    gContext.goSetTwilioChat({...gContext.twilioChat, 
      chatLoading: true,
      chatUsername: chatForm.full_name,
      chatEmail: chatForm.email,
      chatCategory: chatForm.category,
      chatAddress: chatForm.address,
      chatLastBill: chatForm.last_bill,
      visibilityChange: true
    });

    // Create Token  
    const tmpToken = await getToken(chatForm.email);
    if(tmpToken){
      gContext.goSetTwilioChat({...gContext.twilioChat, 
        token: tmpToken
      });
      // Create Conversation      
      const tmpConversation = await createConversation(
        chatForm.full_name,
        chatForm.email,
        chatForm.address,
        chatForm.last_bill,
        gContext.twilioChat.chatUserType,
        chatForm.category 
      );
      if(tmpConversation){
        gContext.goSetTwilioChat({...gContext.twilioChat, 
          logged: true,
          chatUserId: tmpConversation?.participantId,
          chatUsername: chatForm.full_name,
          chatConversationId: tmpConversation?.conversationId,
          token: tmpConversation?.caseId,
          chatCaseId: tmpConversation?.caseId,
          messages: [],
          visibilityChange: true
        });
        updateMessage({
          id: `${Date.now()}-0000-0000-0000-000000000000`,
          type: 'EVENT',
          title: 'Waiting for the agent to join the chat.'},
          null, true);
        sendMessage(message);         
      }
    } else {
      gContext.goSetTwilioChat({...gContext.twilioChat, chatLoading: false});
    }
  };
  
  const receiveMessage = async (data:any, resetTimer:boolean) => {
    if(data && !gContext.twilioChat.messages.some(obj => obj.messageId === data.id)){
      try{
        if(data.properties.Files){
          let tmpFiles:any = await getFiles(data.properties.Files);
          if(Object.keys(tmpFiles).length === 0) tmpFiles = null;
          updateMessage(data, tmpFiles, resetTimer);
        } else {
          updateMessage(data, [], resetTimer);
        }
      } catch (error) {
        console.error('Error fetching message:', error);
      }
    }
  };

  const updateMessage = (data: any, files:any, resetTimer:boolean) => {
    let tmpMessage:Message = {
      senderType: data.type !== 'EVENT' ? data.properties.SenderType : data.type,
      createdAt: moment(new Date()).format(),
      from: data.type !== 'EVENT' ? data.properties.From : data.type,
      message: data.type !== 'EVENT' ? data.properties.Body : data.title,
      form: null,
      files: files,
      messageId: data.id,
      fromId: data.type !== 'EVENT' ? data.createdBy.id : "YouFibre"
    };
    scrollToBottom();
    messageRef.current = [ ...messageRef.current, tmpMessage];
    setNewMessage(true);
  };

  return (
    <ChatArea>
      <ChatContentList id='ChatContentList'>
        { sc.current && <TypingIndicator sc={sc} /> }
        { message && <><Animation>   
          <Splitter />     
            {/* { (Object.keys(message).length - 1) !== i  && <Splitter /> }   */}
            <Message item={message} buttonAction={buttonAction} setChatForm={setChatForm} />
          </Animation></>
        }
        { messages && messages.map((item, index) => 
          <>
          { item.senderType && <Message item={item} buttonAction={buttonAction} setChatForm={setChatForm} /> }
          { (Object.keys(messages).length - 1) !== index  && <Splitter /> }
          </>
        )}
      </ChatContentList>
    </ChatArea>
  );
};

const ChatArea = styled.div`
  width: 100%;
  flex: 1;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
`;

const Animation = styled.div`
  &, .animation {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: inherit;
    margin-bottom: -4rem;
    animation: slideIn .4s cubic-bezier(0.41, 0.39, 0.53, 1.38) forwards;
  }
  @keyframes slideIn {
    from {
      margin-bottom: -4rem;
    }
    to {
      margin-bottom: 0rem;
    }
  }
`;

export default MessageList;
