// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-appointment-success-js": () => import("./../../../src/pages/appointment-success.js" /* webpackChunkName: "component---src-pages-appointment-success-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-careers-fresh-stream-widget-js": () => import("./../../../src/pages/careers/FreshStreamWidget.js" /* webpackChunkName: "component---src-pages-careers-fresh-stream-widget-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-chat-tsx": () => import("./../../../src/pages/chat.tsx" /* webpackChunkName: "component---src-pages-chat-tsx" */),
  "component---src-pages-check-availability-js": () => import("./../../../src/pages/check-availability.js" /* webpackChunkName: "component---src-pages-check-availability-js" */),
  "component---src-pages-checkout-calendar-js": () => import("./../../../src/pages/checkout-calendar.js" /* webpackChunkName: "component---src-pages-checkout-calendar-js" */),
  "component---src-pages-checkout-landing-index-js": () => import("./../../../src/pages/checkout-landing/index.js" /* webpackChunkName: "component---src-pages-checkout-landing-index-js" */),
  "component---src-pages-checkout-landing-landing-business-js": () => import("./../../../src/pages/checkout-landing/LandingBusiness.js" /* webpackChunkName: "component---src-pages-checkout-landing-landing-business-js" */),
  "component---src-pages-checkout-landing-landing-residential-js": () => import("./../../../src/pages/checkout-landing/LandingResidential.js" /* webpackChunkName: "component---src-pages-checkout-landing-landing-residential-js" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-csat-js": () => import("./../../../src/pages/csat.js" /* webpackChunkName: "component---src-pages-csat-js" */),
  "component---src-pages-events-appointment-error-js": () => import("./../../../src/pages/events/appointment-error.js" /* webpackChunkName: "component---src-pages-events-appointment-error-js" */),
  "component---src-pages-events-request-sent-js": () => import("./../../../src/pages/events/request-sent.js" /* webpackChunkName: "component---src-pages-events-request-sent-js" */),
  "component---src-pages-expired-js": () => import("./../../../src/pages/expired.js" /* webpackChunkName: "component---src-pages-expired-js" */),
  "component---src-pages-getstarted-js": () => import("./../../../src/pages/getstarted.js" /* webpackChunkName: "component---src-pages-getstarted-js" */),
  "component---src-pages-landlord-js": () => import("./../../../src/pages/landlord.js" /* webpackChunkName: "component---src-pages-landlord-js" */),
  "component---src-pages-launch-js": () => import("./../../../src/pages/launch.js" /* webpackChunkName: "component---src-pages-launch-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-strapi-legal-articles-article-slug-js": () => import("./../../../src/pages/legal/{StrapiLegalArticles.article_slug}.js" /* webpackChunkName: "component---src-pages-legal-strapi-legal-articles-article-slug-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-mobile-chat-tsx": () => import("./../../../src/pages/mobile-chat.tsx" /* webpackChunkName: "component---src-pages-mobile-chat-tsx" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-strapi-news-articles-slug-js": () => import("./../../../src/pages/news/{strapiNewsArticles.slug}.js" /* webpackChunkName: "component---src-pages-news-strapi-news-articles-slug-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-recontract-js": () => import("./../../../src/pages/recontract.js" /* webpackChunkName: "component---src-pages-recontract-js" */),
  "component---src-pages-refer-js": () => import("./../../../src/pages/refer.js" /* webpackChunkName: "component---src-pages-refer-js" */),
  "component---src-pages-register-bduk-js": () => import("./../../../src/pages/register-bduk.js" /* webpackChunkName: "component---src-pages-register-bduk-js" */),
  "component---src-pages-register-business-tsx": () => import("./../../../src/pages/register-business.tsx" /* webpackChunkName: "component---src-pages-register-business-tsx" */),
  "component---src-pages-register-residential-tsx": () => import("./../../../src/pages/register-residential.tsx" /* webpackChunkName: "component---src-pages-register-residential-tsx" */),
  "component---src-pages-reschedule-calendar-js": () => import("./../../../src/pages/reschedule-calendar.js" /* webpackChunkName: "component---src-pages-reschedule-calendar-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-service-checker-js": () => import("./../../../src/pages/service-checker.js" /* webpackChunkName: "component---src-pages-service-checker-js" */),
  "component---src-pages-setup-js": () => import("./../../../src/pages/setup.js" /* webpackChunkName: "component---src-pages-setup-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-socialtariff-js": () => import("./../../../src/pages/socialtariff.js" /* webpackChunkName: "component---src-pages-socialtariff-js" */),
  "component---src-pages-speedtest-js": () => import("./../../../src/pages/speedtest.js" /* webpackChunkName: "component---src-pages-speedtest-js" */),
  "component---src-pages-strapi-landing-pages-slug-js": () => import("./../../../src/pages/{StrapiLandingPages.slug}.js" /* webpackChunkName: "component---src-pages-strapi-landing-pages-slug-js" */),
  "component---src-pages-success-csat-js": () => import("./../../../src/pages/success-csat.js" /* webpackChunkName: "component---src-pages-success-csat-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-success-order-js": () => import("./../../../src/pages/success-order.js" /* webpackChunkName: "component---src-pages-success-order-js" */),
  "component---src-pages-success-password-change-js": () => import("./../../../src/pages/success-password-change.js" /* webpackChunkName: "component---src-pages-success-password-change-js" */),
  "component---src-pages-success-payment-js": () => import("./../../../src/pages/success-payment.js" /* webpackChunkName: "component---src-pages-success-payment-js" */),
  "component---src-pages-success-recontract-js": () => import("./../../../src/pages/success-recontract.js" /* webpackChunkName: "component---src-pages-success-recontract-js" */),
  "component---src-pages-success-register-interest-js": () => import("./../../../src/pages/success-register-interest.js" /* webpackChunkName: "component---src-pages-success-register-interest-js" */),
  "component---src-pages-switch-js": () => import("./../../../src/pages/switch.js" /* webpackChunkName: "component---src-pages-switch-js" */),
  "component---src-pages-voucher-js": () => import("./../../../src/pages/voucher.js" /* webpackChunkName: "component---src-pages-voucher-js" */),
  "component---src-pages-why-youfibre-js": () => import("./../../../src/pages/why-youfibre.js" /* webpackChunkName: "component---src-pages-why-youfibre-js" */)
}

