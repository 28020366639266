import React from "react";
import styled from 'styled-components';

const WidgetButton = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #08DE9E;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    cursor: pointer;
    -webkit-box-shadow: 2px 1px 8px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 2px 1px 8px 0px rgba(0, 0, 0, 0.27);
    box-shadow: 2px 1px 8px 0px rgba(0, 0, 0, 0.27);
    -webkit-user-select: none;
    user-select: none;  
    img {
        position: absolute;
        -moz-transition: all 0.3s ease;
        transition: all 0.3s ease;
        &.icon-hover {
        width: 15px;
        height: 15px;
            transform: rotate(180deg) scale(.5);
            opacity: 0;
        }
        &.icon-default {
        width: 20px;
        height: 20px;
            transform: rotate(0deg) scale(1);
            opacity: 1;
        }
    }
    &:hover {
        -webkit-box-shadow: 2px 1px 12px 0px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 2px 1px 12px 0px rgba(0, 0, 0, 0.35);
        box-shadow: 2px 1px 12px 0px rgba(0, 0, 0, 0.35);
    }
    &.open {
        .icon-hover {
            transform: rotate(0deg) scale(1);
            opacity: 1;
        }
        .icon-default {
            transform: rotate(180deg) scale(.5);
            opacity: 0;
        }
    }
    &.fullscreen {
        display: none;
    }
    .unread {
        background-color: red;
        padding: 3px;
        display: flex;
        width: 20px;
        height: 20px;
        aspect-ratio: 1 / 1;
        border-radius: 200px;
        color: #ffffff;
        position: absolute;
        top: -4px;
        right: -2px;
        font-size: 0.7rem !important;
        line-height: 0.5rem !important;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: 2px 1px 8px 0px rgba(0, 0, 0, 0.27);
        -moz-box-shadow: 2px 1px 8px 0px rgba(0, 0, 0, 0.27);
        box-shadow: 2px 1px 8px 0px rgba(0, 0, 0, 0.27);
        animation: pulseAnimation 1.5s infinite;
        @keyframes pulseAnimation {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.1);
            }
            100% {
                transform: scale(1);
            }
        }
    }
`;

export default WidgetButton;