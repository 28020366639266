import React, { useEffect, useState, useRef, FormEvent } from 'react';
import { io, Socket } from 'socket.io-client';
import { useDropzone } from 'react-dropzone';
import FormData from 'form-data';
import styled from 'styled-components';
import Chat from './Chat';
import icoYouFibre from '../../../assets/image/rebrand/svg/favicon.svg';
import icoChat from '../../../assets/image/rebrand/svg/chat.svg';
import icoCloseChat from '../../../assets/image/png/ico-close.png';
import loadingIcon from '../../../assets/image/gif/loader-transparent.gif';
import icoUpload from '../../../assets/image/rebrand/svg/youfibre-upload.svg';
import icoClose from '../../../assets/image/rebrand/svg/close.svg';
import notificationSound from "../../../assets/audio/notification.wav";
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { toastrMsg } from '../../../utils/alert';
import { navigate, Link } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
interface Message {
  createdAt: string;
  from?: string | null;
  files?: string | null;
  message: string | null;
  form: FormType | null;
  messageId: string;
  fromId: string;
  senderType: string;
}
interface FormType {
  type: string;
  value: Array<{ title: string; action: string; placeholder?: string | null; type?: string; required?: boolean; options?:object }>;
}

type UserType = 'NEW_CUSTOMER' | 'ACCOUNT_HOLDER' | 'NOT_ACCOUNT_HOLDER';

const Container = styled.div`
  width: 100%;
  max-width: 700px;
  height: 100%;
  position: relative;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  &.direction-column-reverse { flex-direction: column-reverse; }
  &.content-end { justify-content: end; }

`;

const OverflowContainer = styled(Container)`
  overflow-y: auto;
`

const Widget = styled.div`
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 15px;
`;

const Icon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #08DE9E;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  cursor: pointer;
  -webkit-box-shadow: 2px 1px 8px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 2px 1px 8px 0px rgba(0, 0, 0, 0.27);
  box-shadow: 2px 1px 8px 0px rgba(0, 0, 0, 0.27);
  -webkit-user-select: none;
  user-select: none;  
  img {
    position: absolute;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
		&.icon-hover {
      width: 15px;
      height: 15px;
			transform: rotate(180deg) scale(.5);
			opacity: 0;
		}
		&.icon-default {
      width: 20px;
      height: 20px;
			transform: rotate(0deg) scale(1);
			opacity: 1;
		}
  }
  &:hover {
    -webkit-box-shadow: 2px 1px 12px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 2px 1px 12px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 2px 1px 12px 0px rgba(0, 0, 0, 0.35);
  }
  &.open {
		.icon-hover {
			transform: rotate(0deg) scale(1);
			opacity: 1;
		}
		.icon-default {
			transform: rotate(180deg) scale(.5);
			opacity: 0;
		}
  }
  &.fullscreen {
    display: none;
  }

  .unread {
    background-color: red;
    padding: 3px;
    display: flex;
    width: 20px;
    height: 20px;
    aspect-ratio: 1 / 1;
    border-radius: 200px;
    color: #ffffff;
    position: absolute;
    top: -4px;
    right: -2px;
    font-size: 0.7rem !important;
    line-height: 0.5rem !important;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 2px 1px 8px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 2px 1px 8px 0px rgba(0, 0, 0, 0.27);
    box-shadow: 2px 1px 8px 0px rgba(0, 0, 0, 0.27);
    animation: pulseAnimation 1.5s infinite;
    @keyframes pulseAnimation {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`;

const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 350px;
  height: 550px;
  border-radius: 5px;
  -webkit-box-shadow: 2px 1px 12px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 2px 1px 12px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 2px 1px 12px 0px rgba(0, 0, 0, 0.35);
  max-width: 0px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  &.show {
    max-width: 350px;
    max-height: 540px;
  }
  .cover {
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    z-index: 92;
    padding: 1rem;
    width: 100%;
    height: calc(100% - 65px);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    &.closed {
      background: rgba(255, 255, 255, 0.9);
      button { margin-top: 2rem; }
    }
    &.upload {
      background: rgba(9, 222, 158, 0.9);
      button { margin-top: 2rem; }
    }
    animation: showCover .4s cubic-bezier(0.41, 0.39, 0.53, 1.38) forwards;
      
    @keyframes showCover {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  .files {
    margin: 0.5rem;
    border-radius: 5px;
    background-color: #c1f7e7;
    padding: 0rem;
    overlay: none;
    display: flex;  
    flex-direction: column;
    justify-content: center;
    gap: 0rem;
    .file {
      display: flex;
      flex-direction: row;
      border-radius: 3px;
      justify-content: space-between;
      align-items: center;
      margin: 0.2rem;
      padding: 0.2rem 0.5rem;
      gap: 1rem;
      &:hover {
        background-color: #ffffff;
      }
      .fileName {
        font-size: 0.7rem !important;
        line-height: 0.8rem;
        word-break: keep-all;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .action {
        width: 0.8rem;
        height: 0.8rem;
        opacity: 0.7;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }

    }
  }
  &.fullscreen {
    max-width: 100% !important;
    max-height: 100% !important;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    // @media (max-width: 768px) {
    //   #listOfMessages {
    //     max-height: calc(100vh - 210px);
    //   }
    //   .chat-content-container {
    //     padding-bottom: 4rem;
    //   }
    // }
    .cover {
        &.closed {
            height: 100%;
        }
    }
  }
  @media (max-width: 576px) {
    position: fixed;
    z-index: 100;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    &.show {
      max-width: 100%;
      max-height: 100%;
    }
    .cover {
      &.closed, &.upload {
        height: 100%;
      }
    }
  }
`;

const ChatHeader = styled.div`
  background-color: #08DE9E;
  width: 100%;
  padding: 15px;
  color: #ffffff;
  flex: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  -webkit-box-shadow: -2px 2px 5px 1px rgba(0,0,0,0.2);
  box-shadow: -2px 2px 5px 1px rgba(0,0,0,0.2);
  z-index: 91;
  img {
    width: 40px;
    height: 40px;
    -object-fit: fill;
    object-fit: fill;
    padding: 7px;
    border-radius: 200px;
    cursor: pointer;
    background-color: #FFFFFF;
    &.close {
      display: none;
      background-color: transparent;
    }
  }
  a {
    &:hover {
      text-decoration: none !important;
      cursor: pointer;
    }
  }
  span {
    padding: 0px;
  }
  @media (max-width: 576px) {
    img {
      &.close {
        display: block;
      }
    }
  }
`;

const ChatContent = styled.div`
  width: 100%;
  padding: 0;
  color: #000000;
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  overflow-x: hidden;
  .chat-content-container {
    padding-bottom: 1.8rem;
  }
  button {
    display: block;
    background-color: #c1f7e7;
    flex: 0;
    padding: 5px;
    border-radius: 5px;
    color: #263238;
    font-weight: 100;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 100;
    min-width: 40%;
    border: 2px solid #08DE9E;
    cursor: pointer;
    &:hover {
      background-color: #08DE9E;
    }
  }
  @media (max-width: 768px) {
    overflow-y: hidden;
    .chat-content-container {
      overflow-y: auto;
      // padding-bottom: 0;
    }
  }
`;

const ChatInput = styled.form`
  background-color: #f4f7f9;
  width: 100%;
  flex: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 5px;
  .action {
    width: 1.3rem;
    height: 1.3rem;
    cursor: pointer;
    opacity: 0.7;
    -webkit-user-select: none;
    user-select: none;
    &:hover {
      opacity: 0.7;
    }
  }
  input {
    background-color: rgba(0,0,0,0);
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0);
    outline: 0px;
    padding: 0px;
    margin-left: 15px;
    font-size: 0.9rem;
    font-weight: 100;
    flex: 1;
    &.invalid {
      border-bottom: 1px solid red;
    }
  }
  button {
    min-width: 90px;
    background-color: #09de9e;
    flex: 0;
    font-size: 1rem;
    &:hover {
      background-color: #08c98f;
    }
    &:disabled, &:disabled:hover {
      background-color: rgba(0,0,0,0.05);
      color: #aabbcc !important;
    }
  }
  @media (max-width: 576px) {
    padding: 15px;
  }
  // @media (max-width: 768px) {
  //   &.form-fullscreen {
  //     position: fixed;
  //     bottom: 0;
  //   }
  // }  
`;

const Agent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  small {
    position: absolute;
    margin-top: -1.6rem;
    margin-right: 1rem;
    font-size: 0.7rem !important;
    font-weight: 100;
    line-height: 0.8rem;
    color: #015e9c;
    background-color: #ddf0fc;
    border: 1px solid #ffffff;
    border-radius: 100px;
    padding: 5px 15px;
    z-index: 50;
  }
`;
interface Props {
  fullscreen?: boolean;
  className?: string;
}

const TwilioChat = ({fullscreen = false}: Props) => {
  const [logged, setLogged] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const [chatConversationId, setChatConversationId] = useState<string | null>(null);
  const [chatCaseId, setChatCaseId] = useState<string | null>(null);
  const [chatLoading, setChatLoading] = useState<boolean>(false);
  const [chatSending, setChatSending] = useState<boolean>(false);
  const [chatClosing, setChatClosing] = useState<boolean>(false);
  const [openChat, setOpenChat] = useState<boolean>(fullscreen);
  const [chatForm, setChatForm] = useState<{ [key: string]: any }>({});
  const [message, setMessage] = useState<string>('');
  const [chatUserId, setChatUserId] = useState<string | null>(null);
  const [chatUserType, setChatUserType] = useState<UserType | null>(null);
  const [chatUsername, setChatUsername] = useState<string | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [uploadArea, setUploadArea] = useState<boolean>(false);
  const [resetFormValues, setResetFormValues] = useState<boolean>(false);
  const [chatSessionId, setChatSessionId] = useState<string | null>(null);
  const [confirmClosing, setConfirmClosing] = useState<boolean>(false);

  const [chatUnreaded, setChatUnreaded] = useState<boolean>(false);

  const [animateTabTitle, setAnimateTabTitle] = useState<boolean>(false);
  const [titleIndex, setTitleIndex] = useState(-1);
  
  const titleRef = useRef<string>("");
  const intervalRef = useRef<number | null>(null);

  let openChatRef = useRef<boolean>(openChat);
  let visibilityChange = useRef<boolean>(true);

  const fileCount = 5;
  const fileSize = 10; // MB
  const fileExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'gif', 'txt', 'mp4', 'mp3'];
  
  let [lastTimeActive, setLastTimeActive] = useState<any>(null);
  let [countdownInterval, setCountdownInterval] = useState<any>(null);
  let [agentTyping, setAgentTyping] = useState<boolean>(false);
  let agentIdRef = useRef<string|null>(null);

  const defaultMessages:Message[] = [
    {
      createdAt: moment(new Date()).format(),
      message: null,
      fromId: "Client",
      senderType: "CUSTOMER",
      form: {
        type: "buttons",
        value: [
          {
            title: "I am the account holder",
            action: "old_customer"
          },
          {
            title: "I am not the account holder",
            action: "not_customer"
          },
          {
            title: "I am new here",
            action: "new_customer"
          },
        ]
      },
      messageId: "1"
    },
    {
      senderType: "AGENT",
      createdAt: moment(new Date()).format(),
      from: "YouFibre",
      fromId: "YouFibre",
      message: "Welcome to YouFibre!<br />We're going to ask you some questions to ensure we get you through to the right team, so please bear with us!<br />Are you the account holder?",
      form: null,
      messageId: "0"
    }
  ];

  const categories = {
    SALES: "Sales",
    TECH_SUPPORT: "Tech Support",
    INSTALLS_AND_SERVICE_VISITS: "Installs & Service Visits",
    MANAGE_MY_ACCOUNT: "Manage My Account",
    BILLING: "Billing",
    CANCELLATION: "Cancellation",
    COMPLAINT: "Complaint",
  };

  const userTypeLabels = {
    NEW_CUSTOMER: "New customer",
    ACCOUNT_HOLDER: "Account holder",
    NOT_ACCOUNT_HOLDER: "Not the account holder"
  };

  const [messages, setMessages] = useState<Message[]>(defaultMessages);
  const [newMessages, setNewMessages] = useState<Message[]|null>(null);
  const messagesRef = useRef<Message[]>(messages);
  const chatBoxRef = useRef<HTMLDivElement | null>(null);
  const sc = useRef<Socket | null>(null);

  const scrollToBottom = () => {
    try {
      if (chatBoxRef.current) {
        chatBoxRef.current.scrollIntoView({ behavior: "smooth" });
      }
      // let objDiv = document.getElementById("listOfMessages");
      // if(objDiv){ objDiv.scrollTop = objDiv.scrollHeight; }
    } catch(e){ }
  };

  const isConversationActive = async () => {
    if(localStorage.getItem('conversationId')){ 
      try {
        const response = await fetchAsync(`${process.env.GATSBY_API_URL}/ChatModule/v1.0/gateway/conversation/${localStorage.getItem('conversationId')}`, 'get', null, null, 200);
        // Check if the response is okay
        if (!response) {
          console.error("Can't check conversation!");
          return null;
        }
        if(response?.data?.properties?.Status === "CLOSED") resetChat();
        setChatLoading(false);
      } catch (error) { console.error('Error fetching conversation status: ', error); }
    }
  };

  useEffect(() => {
    if (typeof document !== "undefined" && typeof window !== 'undefined'){
      if(animateTabTitle && titleIndex !== -1) {
        if(!localStorage.getItem("title")) localStorage.setItem("title", (typeof document !== "undefined" && document.getElementsByTagName('title')[0].textContent) || "");
        const updateTitle = () => {
          const title = titleRef.current;
          if (title) {
            const nextIndex = (titleIndex + 1) % title.length;
            document.title = title.slice(nextIndex) + " " + title.slice(0, nextIndex);
            setTitleIndex(nextIndex);
          }
        };
        intervalRef.current = window.setInterval(updateTitle, 200); // Change the title every 200ms
        return () => {
          if (intervalRef.current) {
            clearInterval(intervalRef.current); // Cleanup on unmount
          }
        };
      } else {
        intervalRef.current = null;
        titleRef.current = "";
      }
    }
  }, [titleIndex]);
  
  useEffect(() => { 
    isConversationActive();

    localStorage.removeItem("title");
    setChatConversationId(localStorage.getItem('conversationId') || null);
    setChatCaseId(localStorage.getItem('caseId') || null);
    setChatUserId(localStorage.getItem('participantId') || null);
    agentIdRef.current = localStorage.getItem('agentId') || null;
    setChatSessionId(localStorage.getItem('chatSessionId') || null);

    localStorage.getItem('conversationId') && localStorage.getItem('participantId') && setLogged(true);
    localStorage.getItem('chatUsername') && setChatUsername(localStorage.getItem('chatUsername'));
    localStorage.getItem('chatUserType') && setChatUserType(localStorage.getItem('chatUserType') as UserType || 'NEW_CUSTOMER');

    setChatForm({
      full_name: localStorage.getItem('chatUsername'),
      email: localStorage.getItem('chatEmail'),
      category: localStorage.getItem('chatCategory'),
      address: localStorage.getItem('chatAddress'),
      last_bill: localStorage.getItem('chatLastBill'),
    });

    localStorage.getItem('chatMessages') ? 
      setMessages(JSON.parse(localStorage.getItem('chatMessages') || '')) : 
      localStorage.setItem('chatMessages', JSON.stringify(messages));

    localStorage.getItem('conversationId') && getAllMessages(localStorage.getItem('conversationId'), "onLoad");

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        // console.log('Tab is active');
        visibilityChange.current = true;
        if(typeof document !== "undefined" && openChatRef.current && localStorage.getItem("title")){
          setAnimateTabTitle(false);
          setTitleIndex(-1);
          setChatUnreaded(false);
          document.title = localStorage.getItem("title") || "";
          document.getElementById('inputMessage')?.focus();
          setTimeout(() => localStorage.removeItem("title"), 1000);
        }
        localStorage.getItem('conversationId') && getAllMessages(localStorage.getItem('conversationId'), "visibilitychange");
      } else {
        // console.log('Tab is in the background');
        visibilityChange.current = false;
      }
    });
  }, []);
  
  const getAllMessages = async (convID:string|null, location:string) => {
    try{
      if(convID){
        const response:any = await fetchAsync(`${process.env.GATSBY_API_URL}/NotificationModule/v2.0/records/Conversation/${convID}/links?targetEntities=["Message"]`, 'get', null, null, 200);
        if(response){
          let tmpMessages:Message[] = await Promise.all(
            response.data?.Message?.dbRecords.filter(item => !messagesRef.current.some(obj => obj.messageId === item.id)).map(async item => ({
              createdAt: item?.createdAt,
              from: item?.properties?.From,
              files: await getFiles(item?.properties?.Files), 
              message: item?.properties?.Body,
              form: null,
              messageId: item?.id,
              fromId: item?.createdBy?.id,
              senderType: item.type === "EVENT" ? "EVENT" : item.properties.SenderType
            }))
          );         
          if(Object.keys(tmpMessages).length > 0){
            setChatUnreaded(true); 
          }
          tmpMessages = [...tmpMessages, ...messagesRef.current].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
          // console.log("tmpMessages: ", tmpMessages);
          setMessages(tmpMessages);   
          localStorage.setItem('chatMessages', JSON.stringify(tmpMessages)); 
        }
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    if (typeof document !== "undefined") {
      if (fullscreen) {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
        document.documentElement.style.overflow = '';
      }
    }
  }, [fullscreen])

  const connectToWebsocket = (sessionId:string|null = null) => {
    return io(`${process.env.GATSBY_TWILIO_WEBSOCKET}/ChatWebsocketModule/TwilioChatWebsocket/v1.0`, 
      { 
        path: '/ws',
        query: {
          clientId: sessionId 
        },
        reconnection: true,
        reconnectionAttempts: Infinity, // Retry indefinitely
        reconnectionDelay: 1000,       // Start with a 1-second delay
        reconnectionDelayMax: 5000,    // Cap the delay at 5 seconds
        timeout: 10000,                // Timeout after 10 seconds
      }); 
  };

  useEffect(() => {  
    if(chatUserId){
      const tmpUUID = chatSessionId || uuidv4();
      setChatSessionId(tmpUUID);
      localStorage.setItem('chatSessionId', tmpUUID);
  
      if(!sc.current) sc.current = connectToWebsocket(tmpUUID);

      sc.current?.on('connect', () => {
        console.log('Connected to WebSocket server');
      });

      sc.current?.on("connect_error", (err) => {
        console.log(`Connect error due to ${err.message}`);
        sc.current = null;
        sc.current = connectToWebsocket(chatUserId);
      });  

      sc.current?.on("disconnect", (reason) => {
        console.log(`Disconnected: ${reason}`);
        sc.current = null;
        sc.current = connectToWebsocket(chatUserId);
      });

    }
  }, [chatUserId]);

  useEffect(() => {  
    // // Don't delete this code
    // sc.current?.onAny((eventName, ...args) => {
    //   console.log("All events:", eventName, args);
    // });

    // if(!openChat && !localStorage.getItem('conversationId') && sc.current){
    //   sc.current.disconnect();
    //   sc.current = null;
    //   console.log('Disconnected from WebSocket server');
    // }

    sc.current && chatCaseId && sc.current.emit("joinRoom", chatCaseId);

    sc.current && !sc.current.listeners(`case-event-${chatCaseId}`).length && sc.current?.on(`case-event-${chatCaseId}`, (data) => {    
      // console.log(`case-event-${chatCaseId}`, data);
      if(data.caseId === localStorage.getItem('caseId')){
        // console.log(data.type, data.data.properties);        
        switch(data.type){
          case "CASE_UPDATED": 
            agentIdRef.current = data.data.properties.OwnerId; 
            localStorage.setItem('agentId', agentIdRef.current || ""); break;
          case "CONVERSATION_CLOSED": setChatClosing(true); break;
          case "MESSAGE_CREATED": receiveMessage(data.data, true); break;
          default: break;
        }
      }
    });
    sc.current && !sc.current.listeners(`web-chat-input-${chatCaseId}`).length && sc.current?.on(`web-chat-input-${chatCaseId}`, (data) => {
      // console.log(`web-chat-input-${chatCaseId}`, data);
      if(data.caseId === chatCaseId && data.sender === "AGENT"){
        setAgentTyping(true);
        agentIdRef.current && agentHasPosted() && setLastTimeActive(Date.now());
      }
    });

    // sc.current?.recovered

  }, [chatCaseId]);


  let typingTimeout:any;

  useEffect(() => {    
    agentTyping && agentIdRef.current && agentHasPosted() && setLastTimeActive(Date.now());
    typingTimeout = agentTyping ? setTimeout(() => {
      setAgentTyping(false);
      clearTimeout(typingTimeout);
    }, 3000) : false;    
  }, [agentTyping]);

  useEffect(() => {    
    messagesRef.current = messages;  
  }, [messages]);

  useEffect(() => {   
    if(messages.length && chatCaseId){
      sc.current && sc.current?.emit(`web-chat-input`, {
        caseId: chatCaseId,
        conversationId: chatConversationId,
        message: message,
        sender: 'CUSTOMER'
      });
      agentHasPosted() && setLastTimeActive(Date.now());
    }
  }, [message]);

  const getFiles = async (files: String[] | null = null) => {
    try{
      let tmpFiles:any = [];
      if(files){
        const response:any = await fetchAsync(`${process.env.GATSBY_API_URL}/SchemaModule/v1.0/db/File/many?ids=${files}`, 'get', null, null, 200);
          // Check if the response is okay
        if (!response) {
          console.error("Can't close conversation!");
        } else {
          if(Object.keys(response?.data).length){
            response?.data?.map((item) => {
              tmpFiles = [...tmpFiles, {
                id: item?.id || "",
                type: item?.properties?.Mimetype || "",
                url: item?.properties?.Url || ""
              }];
            });
          }
          return (Object.keys(tmpFiles).length !== 0) ? tmpFiles : [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching files:', error);
      return [];
    }
  };
  
  const receiveMessage = async (data:any, resetTimer:boolean) => {
    if(data && !messagesRef.current.some(obj => obj.messageId === data.id)){
      try{
        let tmpFiles:any = [];
        if(data.properties.Files){
          tmpFiles = await getFiles(data.properties.Files);
          if(Object.keys(tmpFiles).length === 0) tmpFiles = null;
          updateMessages(data, tmpFiles, resetTimer);
        } else {
          updateMessages(data, tmpFiles, resetTimer);
        }
      } catch (error) {
        console.error('Error fetching message:', error);
      }
    }
  };

  const agentHasPosted = () => {
    return agentIdRef.current && messagesRef.current.some(obj => obj.fromId === agentIdRef.current);
  };

  const updateMessages = (data: any, files:any, resetTimer:boolean) => {
    let tmpMessage:Message = {
      senderType: data.type !== 'EVENT' ? data.properties.SenderType : data.type,
      createdAt: moment(new Date()).format(),
      from: data.type !== 'EVENT' ? data.properties.From : data.type,
      message: data.type !== 'EVENT' ? data.properties.Body : data.title,
      form: null,
      files: files,
      messageId: data.id,
      fromId: data.type !== 'EVENT' ? data.createdBy.id : "YouFibre"
    };
    if(!openChatRef.current || !visibilityChange.current){
      setChatUnreaded(true); 
    }
    setNewMessages([...(newMessages || []), tmpMessage]);
    agentIdRef.current && agentIdRef.current === tmpMessage.fromId && setLastTimeActive(Date.now());
    setTimeout(() => {
      setNewMessages(null);
      setMessages([tmpMessage, ...messagesRef.current]);
      localStorage.setItem('chatMessages', JSON.stringify([tmpMessage, ...messagesRef.current]));
      setChatLoading(false);
      scrollToBottom();
    }, 400);
  };

  useEffect(() => {
    if(chatUnreaded) {
      titleRef.current = "::: You have new message";
      setAnimateTabTitle(true);
      setTitleIndex(0);
      let sound = new Audio(notificationSound);
      sound.setAttribute('crossorigin', 'anonymous'); 
      sound.play().catch((error) => {
        console.log("User interaction required before playing sound");
      });
    }  
  }, [chatUnreaded]);

  useEffect(() => {
    if(typeof window !== 'undefined' && typeof document !== "undefined"){
      if(openChat){
        if(window.innerWidth < 577) document.body.style.overflow = "hidden";
        intervalRef.current && clearInterval(intervalRef.current);
        if(localStorage.getItem("title")) document.title = localStorage.getItem("title") || "";
        setAnimateTabTitle(false);
        setTitleIndex(-1);
        setChatUnreaded(false);
        document.getElementById('inputMessage')?.focus();
        setTimeout(() => localStorage.removeItem("title"), 1000);
      } else {
        document.body.style.overflow = "";
        setConfirmClosing(false);
        setChatClosing(false);
      }
    }
    scrollToBottom();
    openChatRef.current = openChat;
    if(openChat && logged && !messagesRef?.current[0]?.form  && agentHasPosted()) setLastTimeActive(Date.now());
    else {
      clearInterval(countdownInterval);
      lastTimeActive = null;
      countdownInterval = null;
    }
  }, [openChat]);

  const validateInput = (data) => {
    // // Don't delete this code
    // if(data.length === 0) return true;
    // const regex = /^[a-zA-Z0-9\s\-,.!?()/@#&£€$=+*"']+$/;
    // return regex.test(data);
    return true;
  };

  const resetChat = () => {
    sc.current && chatCaseId && sc.current.emit("leaveRoom", chatCaseId);
    setChatConversationId(null);
    setChatCaseId(null);
    setUploadedFiles([]);
    setResetFormValues(!resetFormValues);
    localStorage.removeItem('_timer');
    localStorage.removeItem('chatEmail');
    localStorage.removeItem('conversationId');
    localStorage.removeItem('caseId');
    localStorage.removeItem('participantId');
    localStorage.removeItem('chatUsername');
    localStorage.removeItem('twilio_token');
    localStorage.removeItem('chatMessages');
    localStorage.removeItem('chatUserType');
    localStorage.removeItem('chatCategory'); 
    localStorage.removeItem('chatAddress'); 
    localStorage.removeItem('chatLastBill');
    localStorage.removeItem('agentId');
    setChatUsername(null);
    setChatUserType(null);
    setChatUserId(null);
    setChatForm({});
    setChatLoading(false);
    setLogged(true);
    setToken(null);
    agentIdRef.current = null;
    setMessage('');
    clearInterval(countdownInterval);
    lastTimeActive = null;
    countdownInterval = null;
    localStorage.setItem('chatMessages', JSON.stringify(defaultMessages));
    setNewMessages(null);
    setMessages(defaultMessages);
    scrollToBottom();
    fullscreen && setOpenChat(true);
  };

  const fetchAsync = async (url = '', method = 'get', headers:object|null = {}, body:object|null = {}, statusCode = 201) => {
    try {
      const response = await fetch(url, {
          method: method.toLowerCase(),
          headers: {
              "Authorization": `Bearer ${process.env.GATSBY_API_TOKEN}`,
              "Content-Type": "application/json",
              ...headers
          },
          // Convert the body to JSON only if it's not empty
          body: body && Object.keys(body).length ? JSON.stringify(body) : null
      });
      // Check if the response is okay
      if (!response) {
        console.error('Error fetching data: ', url);
          return null;
      }
      const data = await response.json();
      // Check if the statusCode matches
      return data?.statusCode === statusCode ? data : null;
    } catch (error) { console.error('Error fetching:', error); }
  };

  const getToken = async (tmpEmail) => {
    try {
      const data = await fetchAsync(`${process.env.GATSBY_API_URL}/ChatModule/v1.0/gateway/token?identity=${tmpEmail}&source=twilio`, 'get', null, null, 200);
      return data?.statusCode === 200 ? data?.data : null;
    } catch (error) { console.error('Error fetching token:', error); }
  };

  const createConversation = async (
    tmpChatUsername:string|null,
    tmpChatEmail:string|null,
    tmpChatAddress:string|null,
    tmpChatLastBill:string|null,
    tmpChatUserType:string|null,
    tmpChatCategory:string|null
  ) => {    
    try {
      const response = await fetchAsync(`${process.env.GATSBY_API_URL}/ChatModule/v1.0/gateway/conversation/create`, 'post', null, 
        {
          source: "twilio",
          channel: "WEB_CHAT",
          identifier: tmpChatEmail || null,
          DpaCustomerName: tmpChatUsername || null,
          DpaCustomerEmail: tmpChatEmail || null,
          DpaCustomerAddress: tmpChatAddress || null,
          DpaCustomerLastBill: tmpChatLastBill || null,
          DpaCustomerType: tmpChatUserType || null,
          DpaCustomerCategory: tmpChatCategory || null
        }, 201);
      // Check if the response is okay
      if (!response) {
        console.error("Can't create conversation!");
        return null;
      }
      // Check if the statusCode matches
      return response?.statusCode === 201 ? response?.data : null;
    } catch (error) { console.error('Error creating conversation:', error); }
  };

  const closeConversation = async (closedBy:string = "CUSTOMER") => {
    try {
      const response = await fetchAsync(
        `${process.env.GATSBY_API_URL}/ChatModule/v1.0/gateway/conversation/${localStorage.getItem('conversationId')}/close`, 
        'post', 
        null, 
        { closedBy: closedBy }, 
        201
      );
      // Check if the response is okay
      if (!response) {
        console.error("Can't close conversation!");
        return null;
      }
      // Check if the statusCode matches
      response?.statusCode === 201 && resetChat();
      clearInterval(countdownInterval);
      lastTimeActive = null;
      countdownInterval = null;
    } catch (error) { console.error('Error closing conversation:', error); }
  };

  const sendMessage = async (btnMessage:string|null = null) => {
    if(message.length > 0 || btnMessage || Object.keys(uploadedFiles).length > 0) {
      typeof document !== "undefined" && document.getElementById('inputMessage')?.focus();
      let formData:FormData = new FormData();
      formData.append('conversationSid', localStorage.getItem('conversationId'));
      formData.append('message', message || btnMessage);
      formData.append('from', localStorage.getItem('chatUsername'));
      formData.append('sender', 'CUSTOMER');
      setMessage('');
      setChatSending(true);
      try{
        Object.keys(uploadedFiles).length > 0 && uploadedFiles.map(async (item) => {
          formData.append('files', item);
        });  
      } catch (e){

      }
      // Send a Message to the Conversation
      fetch(`${process.env.GATSBY_API_URL}/ChatModule/v1.0/gateway/conversation/${localStorage.getItem('conversationId')}/message/send`, {
        method: 'post',
        headers: {
          "Authorization": `Bearer ${process.env.GATSBY_API_TOKEN}`,
        },
        body: formData as unknown as BodyInit
      })
      .then(res => res.json())
      .then(data => {
        if(data?.statusCode === 201){   
          setUploadedFiles([]);
          setLogged(true);
          setChatSending(false);
          setChatLoading(false);
        } 
        else if(data?.statusCode === 500){
          resetChat();
          console.error("Conversation is closed");
        }
      })
      .catch(error => {
        console.error('Send a Message:', error);
      });
    }
  };

  const submitMessage = (e) => {
    e.preventDefault();
    sendMessage();
  };

  const submitForm = async (message:string = '') => { 
    console.log(messagesRef.current.slice(1, 0));
    // setMessages(messagesRef.current.slice(1, 0));
    setChatLoading(true);
    localStorage.setItem('chatUsername', chatForm.full_name);
    setChatUsername(chatForm.full_name);
    localStorage.setItem('chatEmail', chatForm.email);
    localStorage.setItem('chatCategory', chatForm.category);  
    localStorage.setItem('chatAddress', chatForm.address); 
    localStorage.setItem('chatLastBill', chatForm.last_bill);
    // Create Token
    const tmpToken = await getToken(chatForm.email);
    if(tmpToken){
      localStorage.setItem('twilio_token', tmpToken);
      setToken(tmpToken);
      // Create Conversation      
      const tmpConversation = await createConversation(
        chatForm.full_name,
        chatForm.email,
        chatForm.address,
        chatForm.last_bill,
        chatUserType,
        chatForm.category 
      );
      if(tmpConversation){
        setLogged(true);
        setChatUserId(tmpConversation?.participantId);
        localStorage.setItem('conversationId', tmpConversation?.conversationId);
        localStorage.setItem('caseId', tmpConversation?.caseId);
        localStorage.setItem('participantId', tmpConversation?.participantId);
        setChatCaseId(tmpConversation?.caseId);
        setChatConversationId(tmpConversation?.conversationId);
        new Promise<void>((resolve) => {
          setMessages((prev) => {
            resolve();
            return [];
          });
          sendMessage(message);  
        }).then(() => {
          if(tmpConversation) {          
            updateMessages({type: 'EVENT', title: 'Waiting for the agent to join the chat.'}, null, true);
          }
        });
      }
    } else {
      setChatLoading(false);
    }
  };

  useEffect(() => {
    if(countdownInterval) clearInterval(countdownInterval);
    if(lastTimeActive){ 
      setCountdownInterval(null);
      resetInactivityTimer();
    }
  }, [lastTimeActive]);

  useEffect(() => {
    lastTimeActive !== null && countdownInterval === null && resetInactivityTimer();
  }, [countdownInterval]);

  const resetInactivityTimer = () => {
    lastTimeActive !== null && setCountdownInterval(setInterval(() => {
      const elapsedSeconds = lastTimeActive > 0 ? Math.ceil((Date.now() - lastTimeActive) / 1000) : null;
      if(elapsedSeconds !== null){
        localStorage.setItem('_timer', elapsedSeconds.toString());
        // console.log(elapsedSeconds);        
        if(elapsedSeconds && elapsedSeconds === parseInt(process.env.GATSBY_TWILIO_TIME1 || '300') && lastTimeActive) {    // After: 300s = 5min
          receiveMessage({
            id: `${Date.now()}-0000-0000-0000-000000000000`,
            createdBy: {
              id: `${Date.now()}-0000-0000-0000-${Date.now()}`
            },
            properties:{
              SenderType: "AGENT",
              From: "YouFibre",
              Body: "Could you please confirm if you're still waiting on this chat? If so, kindly send a reply. If we don’t hear from you, the chat will automatically close. Thank you.",
              Files: null
            }
          },false);
        } else if(elapsedSeconds && elapsedSeconds > parseInt(process.env.GATSBY_TWILIO_TIME2 || '360')){ // After: 360s = 6min
            clearInterval(countdownInterval);
            lastTimeActive = null;
            countdownInterval = null;
            closeConversation("SYSTEM");
            setChatClosing(true);
            resetChat();
        } else {
          // console.log("2 : ", elapsedSeconds);
        }
      } else {
        clearInterval(countdownInterval);
        lastTimeActive = null;
        countdownInterval = null;
      }
      return true;
    }, 1000)); 
  };

  const newAccountHolder = () => {
    setChatUserType('NEW_CUSTOMER');
    localStorage.setItem('chatUserType', 'NEW_CUSTOMER');
    let tmpMessages:Message[] = [
      {
        senderType: "CUSTOMER",
        createdAt: moment(new Date()).format(),
        from: "YouFibre",
        fromId: "YouFibre",
        message: null,
        form: {
          type: "form",
          value: [
            {
              title: "Name",
              action: "full_name",
              placeholder: null,
              type: 'text',
              required: true
            },
            {
              title: "Email",
              action: "email",
              placeholder: null,
              type: 'email',
              required: true
            },
            {
              title: "Enter your Postcode",
              action: "address",
              placeholder: "Example: SW1 4EN",
              type: 'zip',
              required: true
            },
            {
              title: "What do you need help with today?",
              action: "category",
              placeholder: null,
              type: 'select',
              required: true,
              options: {
                SALES: "Sales",
                TECH_SUPPORT: "Tech Support",
                COMPLAINT: "Complaint",
              },
            },
            {
              title: "Submit",
              action: "submit_form",
              type: 'button'
            }
          ]
        },
        messageId: "3"
      },
      {
        senderType: "AGENT",
        createdAt: moment(new Date()).format(),
        from: "YouFibre",
        fromId: "YouFibre",
        message: "We're so happy to hear from you! So that we can provide the best possible support, please provide us with the following information.",
        form: null,
        messageId: "2"
      },
      {
        senderType: "CUSTOMER",
        createdAt: moment(new Date()).format(),
        from: "Client",
        fromId: "Client",
        message: 'I am new here',
        form: null,
        messageId: "1"
      }, ...messages.slice(1)];
    setNewMessages([...(newMessages || []), tmpMessages[0]]);
    setTimeout(() => { setNewMessages(null); setMessages(tmpMessages);}, 400);
    // setMessages(tmpMessages);
    localStorage.setItem('chatMessages', JSON.stringify(tmpMessages));  
  };

  const notAccountHolder = () => {
    setChatUserType('NOT_ACCOUNT_HOLDER');
    localStorage.setItem('chatUserType', 'NOT_ACCOUNT_HOLDER');
    let tmpMessages:Message[] = [
      {
        senderType: "CUSTOMER",
        createdAt: moment(new Date()).format(),
        from: "YouFibre",
        fromId: "YouFibre",
        message: null,
        form: {
          type: "form",
          value: [
            {
              title: "Name",
              action: "full_name",
              placeholder: null,
              type: 'text',
              required: true
            },
            {
              title: "Email",
              action: "email",
              placeholder: null,
              type: 'email',
              required: true
            },
            {
              title: "Enter your Postcode",
              action: "address",
              placeholder: "Example: SW1 4EN",
              type: 'zip',
              required: true
            },
            {
              title: "What do you need help with today?",
              action: "category",
              placeholder: null,
              type: 'select',
              required: true,
              options: {
                SALES: "Sales",
                TECH_SUPPORT: "Tech Support",
                COMPLAINT: "Complaint",
              },
            },
            {
              title: "Submit",
              action: "submit_form",
              type: 'button'
            }
          ]
        },
        messageId: "3"
      },
      {
        senderType: "AGENT",
        createdAt: moment(new Date()).format(),
        from: "YouFibre",
        fromId: "YouFibre",
        message: "We're so happy to hear from you! So that we can provide the best possible support, please provide us with the following information.",
        form: null,
        messageId: "2"
      },
      {
        senderType: "CUSTOMER",
        createdAt: moment(new Date()).format(),
        from: "Client",
        fromId: "Client",
        message: 'I am not the account holder',
        form: null,
        messageId: "1"
      }, ...messages.slice(1)];
    setNewMessages([...(newMessages || []), tmpMessages[0]]);
    setTimeout(() => { setNewMessages(null); setMessages(tmpMessages);}, 400);
    // setMessages(tmpMessages);
    localStorage.setItem('chatMessages', JSON.stringify(tmpMessages));  
  };

  const yesAccountHolder = () => {
    setChatUserType('ACCOUNT_HOLDER');
    localStorage.setItem('chatUserType', 'ACCOUNT_HOLDER');
    let tmpMessages:Message[] = [
      {
        senderType: "CUSTOMER",
        createdAt: moment(new Date()).format(),
        from: "YouFibre",
        fromId: "YouFibre",
        message: null,
        form: {
          type: "form",
          value: [
            {
              title: "Name",
              action: "full_name",
              placeholder: null,
              type: 'text',
              required: true
            },
            {
              title: "Email",
              action: "email",
              placeholder: null,
              type: 'email',
              required: true
            },
            {
              title: "Enter your Postcode",
              action: "address",
              placeholder: "Example: SW1 4EN",
              type: 'zip',
              required: true
            },
            {
              title: "What was the total value of your last bill?",
              action: "last_bill",
              placeholder: 'Total amount of your last bill',
              type: 'number',
              required: true
            },
            {
              title: "What do you need help with today?",
              action: "category",
              placeholder: null,
              type: 'select',
              required: true,
              options: {
                SALES: "Sales",
                TECH_SUPPORT: "Tech Support",
                INSTALLS_AND_SERVICE_VISITS: "Installs & Service Visits",
                MANAGE_MY_ACCOUNT: "Manage My Account",
                BILLING: "Billing",
                CANCELLATION: "Cancellation",
                COMPLAINT: "Complaint",
              },
            },
            {
              title: "Submit",
              action: "submit_form",
              type: 'button'
            }
          ]
        },
        messageId: "3"
      },
      {
        senderType: "AGENT",
        createdAt: moment(new Date()).format(),
        from: "YouFibre",
        fromId: "YouFibre",
        message: "We're so happy to hear from you! So that we can provide the best possible support, please provide us with the following information.",
        form: null,
        messageId: "2"
      },
      {
        senderType: "CUSTOMER",
        createdAt: moment(new Date()).format(),
        from: "Client",
        fromId: "Client",
        message: 'I am the account holder',
        form: null,
        messageId: "1"
      }, ...messages.slice(1)];
    setNewMessages([...(newMessages || []), tmpMessages[0]]);
    setTimeout(() => { setNewMessages(null); setMessages(tmpMessages);}, 400);
    // setMessages(tmpMessages);
    localStorage.setItem('chatMessages', JSON.stringify(tmpMessages));   
  };

  const chatToggle = () => {
    if(chatClosing){
      resetChat();
      setChatClosing(false);
    }
    setOpenChat(!openChat);
  };
  
  const buttonAction = (action) => {
    switch(action){
      case 'new_customer': newAccountHolder(); break;
      case 'not_customer': notAccountHolder(); break;
      case 'old_customer': yesAccountHolder(); break;
      case 'submit_form':
          chatForm.full_name && 
          chatForm.address && chatForm.address !== '/' && 
          chatForm.email && 
          (((localStorage.getItem("chatUserType") === "ACCOUNT_HOLDER") && typeof chatForm.last_bill !== "undefined") || (localStorage.getItem("chatUserType") !== "ACCOUNT_HOLDER")) &&
          chatForm.category && 
          chatUserType && 
          submitForm(`<b>${userTypeLabels[chatUserType]}</b><br /><b>Full name</b>: ${chatForm.full_name}<br /><b>Email</b>: ${chatForm.email}<br /><b>Address</b>: ${chatForm.address}<br />${(typeof chatForm.last_bill !== 'undefined')?'<b>What was the total value of your last bill</b>: '+chatForm.last_bill+'<br />':''}<b>What do you need help with today</b>: ${categories[chatForm.category]}`);
        break;
      default: break;
    }
  };

  const {getRootProps, getInputProps, open} = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles:any) => {
      if(chatCaseId){
        acceptedFiles = acceptedFiles.filter(item => item.size < (fileSize*1048576) ? item : toastrMsg(`Maximum file size is ${fileSize}MB`, "error"));
        acceptedFiles = acceptedFiles.filter(item => fileExtensions.some(ext => item.name.endsWith(ext)) ? item : toastrMsg(`Unsupported File extension`, "error"));
        acceptedFiles = acceptedFiles.filter(item => Object.keys(uploadedFiles.filter(ext => ext.name === item.name)).length === 0);      
        Object.keys(uploadedFiles).length + Object.keys(acceptedFiles).length < fileCount+1 ? 
          setUploadedFiles([...uploadedFiles, ...acceptedFiles]) : 
          toastrMsg(`Maximum ${fileCount} files`, "warning");
        setUploadArea(false);
      }
    },
  });

  const deleteFile = (name:string) => {
    const tmpFiles = uploadedFiles.filter((itm:any) => itm.name !== name);
    setUploadedFiles(tmpFiles);
  };

  return (
    <>
    { (typeof process.env.GATSBY_TWILIO_CHAT_VERSION === 'undefined' || process.env.GATSBY_TWILIO_CHAT_VERSION === '1') && 
    <Widget>
      <ChatWrapper
        className={`${openChat ? 'show' : ''} ${fullscreen?'fullscreen':''}`}
        {...getRootProps()}
        onDragOver={() => chatCaseId && setUploadArea(true)}
        onMouseLeave={() => setUploadArea(false)}
      >
        
        <ChatHeader>
          <Container className='justify-between'>
            <Row className='p-0 m-0 align-items-center'>
              <Link to="/">
                <img src={icoYouFibre} onClick={() => navigate('')}/>
                <span>&nbsp;YouFibre</span>
              </Link>
            </Row>            
            <Row className='p-0 m-0 align-items-center'>
              {!chatConversationId && Object.keys(messages).length > 2 && <button onClick={() => resetChat()} className='small'>Back</button>}
              {chatConversationId && <button onClick={() => setConfirmClosing(true)} className='small'>End conversation</button>}
              {!fullscreen && <img src={icoCloseChat} className='close' onClick={() => setOpenChat(!openChat)} />}
            </Row>          
          </Container>
        </ChatHeader>
        <ChatContent>
          <Container className={`direction-column-reverse content-end chat-content-container`}>
            <div ref={chatBoxRef} />
            <Chat
              messages={messages}
              chatUsername={chatUsername}
              chatUserId={chatUserId}
              chatConversationId={chatConversationId}
              resetFormValues={resetFormValues}
              setChatForm={setChatForm}
              buttonAction={buttonAction}
              newMessages={newMessages}
            />
          </Container>
        </ChatContent>      
        { agentTyping &&<Agent><small>Agent is typing...</small></Agent> }
        { logged && !chatClosing && !messages[0]?.form && <ChatInput className={`${fullscreen?'form-fullscreen':''}`}><OverflowContainer>
          <input id="inputMessage" className={!validateInput(message) ? 'invalid' : ''} autoComplete='off' maxLength={500} onChange={(e) => setMessage(e.target.value)} value={message} type="text" placeholder="Write a reply..." />
          <input {...getInputProps()} />
          <img className='action' alt='Add attachment' src={icoUpload} onClick={() => open()} />
          <button type='submit' onClick={(e) => submitMessage(e)} disabled={chatSending || !validateInput(message)}>{ chatSending ? <img src={loadingIcon} width={16} height={16} /> : 'Send'}</button>
        </OverflowContainer></ChatInput> }
        {Object.keys(uploadedFiles).length > 0 && <div className='files'>
          {uploadedFiles.map((file:any) => (
            <div className='file' key={file.name}>
              <div className='fileName'>{file.name}</div>
              <img className='action' src={icoClose} onClick={() => deleteFile(file.name)} />
            </div>
          ))}
        </div>}
        { uploadArea && chatCaseId && <div className='cover upload'>
          <h4>Drag and drop your files here</h4>
          <p>Maximum {fileCount} files. Limit {fileSize}MB per file.</p>
          <p>Supported files: {fileExtensions && fileExtensions.map((item:string, index) => !['mp4','mp3'].some(ext => ext === item) && (index === 0 ? item : `, ${item}`))}</p>
        </div> }
        { chatLoading && <div className='cover'><img className="make-it-white" src={loadingIcon} width={14} height={14} /></div> }
        { chatClosing && <div className='cover closed'>
          <h4>Conversation is closed</h4>
          <span>To keep the conversation going, create a new conversation.</span>  
          <button onClick={() => { setChatClosing(false); resetChat();}}>Create new</button>
        </div> }
        { confirmClosing && <div className='cover closed'>
          <h4>Close conversation</h4>
          <span>Are you sure you want to end this conversation?</span>  
          <button onClick={() => { setConfirmClosing(false);}}>Cancel</button>
          <button onClick={() => { setConfirmClosing(false); closeConversation("CUSTOMER"); resetChat();}} className='blue-bg'>Confirm</button>
        </div> }
      </ChatWrapper>
      <Icon onClick={() => chatToggle()} className={`YFChat ${openChat ? 'open' : ''} ${fullscreen?'fullscreen':''}`}>
        <img src={icoChat} className='icon-default' />
        <img src={icoCloseChat} className='icon-hover' />
        {chatUnreaded && <small className='unread'>1</small>}
      </Icon>
    </Widget>}
    </>
  );
};

export default TwilioChat;
