import React, { useContext, useEffect, useState } from "react";
import styled from 'styled-components';
import GlobalContext from "../../../../context/GlobalContext";

const TypingIndicator = ({sc}) => {
  const gContext: any = useContext(GlobalContext);
  const [typingAgent, setTypingAgent] = useState(false);

  useEffect(() =>{
    if(sc.current && gContext.twilioChat.chatCaseId){
      sc.current.emit("joinRoom", gContext.twilioChat.chatCaseId);  
      !sc.current.listeners(`web-chat-input-${gContext.twilioChat.chatCaseId}`).length && sc.current?.on(`web-chat-input-${gContext.twilioChat.chatCaseId}`, (data) => {
        if(data.sender === "AGENT"){
          setTypingAgent(true);
        }
      });
    }
  }, [sc, gContext.twilioChat.chatCaseId]);
  
  let typingTimeout:any;
  
  useEffect(() => {
    if(typingAgent){
      setTypingAgent(true);
      typingTimeout = setTimeout(() => {
        setTypingAgent(false);
        clearTimeout(typingTimeout);
      }, 3000);   
    }
  }, [typingAgent]);

  return ( typingAgent ? <Agent><small>Agent is typing...</small></Agent> : null );
};

export default TypingIndicator;

const Agent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  small {
    position: absolute;
    margin: 0rem 0.2rem 0rem 0rem;
    font-size: 0.7rem !important;
    font-weight: 100;
    line-height: 0.8rem;
    color: #015e9c;
    background-color: #ddf0fc;
    border: 1px solid #ffffff;
    border-radius: 100px;
    padding: 5px 15px;
    z-index: 50;
  }
`;