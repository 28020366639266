import React, { useContext, useEffect, useRef, useState } from "react";
import Widget from "./components/Widget";
import GlobalContext from "../../../context/GlobalContext";
import Chat from "./Chat";
import { defaultMessages, initialTwilioChatState } from "./utils/chats";
import ChatWrapper from "./components/styled/ChatWrapper";
import { Message, chatLocalStorage, findUnreadedMessages, getAllMessages, isConversationActive } from "./services/chatService";

const TwilioChat2 = ({fullscreen = false, side = "right"}) => {
    const gContext: any = useContext(GlobalContext);

    const [animateTabTitle, setAnimateTabTitle] = useState<boolean>(false);
    const messages = useRef<Message[]>([]);
    const [titleIndex, setTitleIndex] = useState(-1);
  
    useEffect(() => {    
        if(fullscreen && typeof document !== "undefined") document.getElementById("main_chat")?.remove();
        // Check if Conversation is closed in mean time
        const fetchConversationActive = async () => {
            try {
                const response:any = await isConversationActive();
                if(response === "CLOSED"){
                  gContext.goResetTwilioChat();
                }
            } catch (error) {
              console.error("Error fetching is conversation active:", error);
            }
        };
        fetchConversationActive(); 

        // On reload get chat messages from previous session / on page reload
        const fetchExistingMessages = async (onlyMessages:boolean = false) => {
            try { 
                const tmpLocalStorage = await chatLocalStorage(); 
                if(chatLocalStorage('chatConversationId') && tmpLocalStorage){
                    const tmpMessages:any = await getAllMessages(); 
                    if(tmpMessages){
                        const tmpUnreadedMessages = await findUnreadedMessages(messages.current, tmpMessages);
                        if(tmpMessages && tmpLocalStorage && tmpUnreadedMessages){                          
                            const tmpState = 
                            onlyMessages ? 
                                {
                                    ...gContext.twilioChat,
                                    messages: tmpMessages,
                                    chatUnreaded: gContext.twilioChat.chatConversationId && (!gContext.twilioChat.openChat || !gContext.twilioChat.visibilityChange) && Object.keys(tmpUnreadedMessages).length > 1,
                                    fullscreen: fullscreen,
                                    side: side
                                } : 
                                {
                                    ...initialTwilioChatState, 
                                    ...tmpLocalStorage || [],
                                    messages: tmpMessages,
                                    chatUnreaded: gContext.twilioChat.chatConversationId && (!gContext.twilioChat.openChat || !gContext.twilioChat.visibilityChange) && Object.keys(tmpUnreadedMessages).length > 1,
                                    fullscreen: fullscreen,
                                    side: side
                                };
                            gContext.goSetTwilioChat(tmpState);
                            messages.current = tmpMessages;
                            return tmpState;     
                        }
                    }
                } else {
                    const tmpState = {
                        ...initialTwilioChatState,
                        ...tmpLocalStorage || [],
                        chatUserType: null,
                        openChat: false,
                        fullscreen: fullscreen,
                        side: side
                    };  
                    gContext.goSetTwilioChat(tmpState);
                    messages.current = defaultMessages;
                    return tmpState;   
                }
            } catch (error) {
                console.error("Error fetching is conversation active:", error);
            }
        };
        fetchExistingMessages();
    
        localStorage.removeItem("title");

        document.addEventListener('visibilitychange', async () => {
            const response:any = await fetchExistingMessages();
            if(response){
                if (document.visibilityState === 'visible') {
                    if(typeof document !== "undefined" && localStorage.getItem("title")){
                        document.title = localStorage.getItem("title") || "";
                    } 
                    setAnimateTabTitle(false);
                    setTitleIndex(-1);
                    gContext.goSetTwilioChat({ 
                        ...response,
                        visibilityChange: true
                    });
                    document.getElementById('inputMessage')?.focus();
                } else if (document.visibilityState === 'hidden') {
                    gContext.goSetTwilioChat({ 
                        ...response,
                        visibilityChange: false
                    });
                } else {
                    gContext.goSetTwilioChat({ 
                        ...response,
                        visibilityChange: true
                    });
                }
            }
        });
    }, []);

    useEffect(() => {
      if (typeof document !== "undefined") {
        if (fullscreen) {
          document.body.style.overflow = 'hidden';
          document.documentElement.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = '';
          document.documentElement.style.overflow = '';
        }
      }
    }, [fullscreen])
  
    return <> 
        { process.env.GATSBY_TWILIO_CHAT_VERSION === "2" &&
        <ChatWrapper className={side || 'right'}>
            { gContext.twilioChat.openChat && <Chat /> }
            { !fullscreen && <Widget /> }
        </ChatWrapper>
        }
    </>;
};

export default TwilioChat2;
